import React, { useState } from 'react';

function Careers() {
  const [selectedJob, setSelectedJob] = useState(null);

  // Job descriptions
  const jobDescriptions = {
    'Site Engineer': 'Responsible for overseeing and managing the construction site, ensuring safety, and maintaining progress according to schedule.',
    'Project Manager': 'Leads the project, coordinates between teams, manages timelines and budget, and ensures successful project delivery.',
    'Planning Engineer': 'Focuses on project planning, scheduling, monitoring progress, and making sure milestones are met efficiently.',
    'Procurement Manager': 'Oversees the sourcing and purchasing process, negotiates supplier contracts, ensures quality and cost-efficiency, and manages supplier relationships.',
    'Purchase Manager': 'Manages all the purchases related to the project efficiently and ensures cost-effectiveness.',
    'Billing & Estimation Manager' :'Manages all thee bills related to the project and creates estimations for the projects',
  };

  return (
    <div style={containerStyle}>
      {/* Left Content Section */}
      <div style={leftSectionStyle}>
        <h1 style={headingStyle}>Available Positions</h1>
        <ul style={listStyle}>
          {Object.keys(jobDescriptions).map((job) => (
            <li
              key={job}
              style={listItemStyle(selectedJob === job)}
              onClick={() => setSelectedJob(job)}
            >
              {job}
            </li>
          ))}
        </ul>
      </div>

      {/* Right Form Section */}
      <div style={rightSectionStyle}>
        {selectedJob ? (
          <>
            <h2 style={subHeadingStyle}>{selectedJob}</h2>
            <p style={paragraphStyle}>{jobDescriptions[selectedJob]}</p>
          </>
        ) : (
          <p style={paragraphStyle}>Select a position to see the description.</p>
        )}
      </div>
    </div>
  );
}

// CSS-in-JS Styles
const containerStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  minHeight: 'auto',
  padding: '50px 5%',
  gap: '30px',
};

const leftSectionStyle = {
  flex: 1,
  minWidth: '300px',
  paddingRight: '20px',
};

const rightSectionStyle = {
  flex: 1,
  minWidth: '300px',
  border: '2px solid #f0c419',
  borderRadius: '10px',
  padding: '30px',
};

const headingStyle = {
  fontSize: '3rem',
  fontWeight: 'bold',
};

const subHeadingStyle = {
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '10px',
};

const paragraphStyle = {
  marginTop: '20px',
  fontSize: '1.2rem',
  lineHeight: '1.8',
};

const listStyle = {
  listStyleType: 'none',
  padding: 0,
};

const listItemStyle = (isSelected) => ({
  marginBottom: '10px',
  cursor: 'pointer',
  padding: '10px',
  borderRadius: '5px',
  backgroundColor: isSelected ? '#f0c419' : 'transparent',
  color: isSelected ? '#fff' : '#000',
  fontWeight: 'bold', // Always bold
  textDecoration: isSelected ? 'underline' : 'none', // Optional for selected item emphasis
});

export default Careers;
