import React from 'react';

function MainInfo() {
  return (
    <section className="tc-main-info-style2">
      <div className="container">
        <div className="row gx-0">
          <div className="col-lg-4">
            <h4 className="fsz-75 fw-bold ">
              Interior Design and Fit-Out
            </h4>
            
          </div>
          <div className="col-lg-6 offset-lg-2 mt-5 mt-lg-0">
            <h6
              className="fsz-24 fw-400 mt-4 mt-lg-0 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              Our interior designers interpret each client’s desires and style preferences to provide pioneering and exquisite interiors, curating furniture, materials, art, and antiques. Interior spaces are often completely re-imagined beyond the ornamental. Each of our projects are as individualized as a piece of art or couture.
            </h6>
            
            
            
          </div>
        </div>
       
      </div>
    </section>
  );
}

export default MainInfo;
