import React from 'react';

function Footer() {
  return (
    <footer className="tc-footer-style1 border-top" style={{ padding: '20px 0', backgroundColor: '#dddddd' }}> {/* Light gray background */}
      <div className="container">
        <div className="top-content" style={{ padding: '20px 0' }}>
          <div className="row gx-0">
            <div className="col-lg-4 d-flex flex-column align-items-start">
              <div className="info-side">
                <h5 style={{ marginBottom: '10px' }}>Follow Us On</h5>
                <div className="foot-social" style={{ marginTop: '10px' }}>
                  <a href="#">
                    <i className="fab fa-x-twitter" style={{ fontSize: '20px' }}></i>
                  </a>
                  <a href="#" style={{ marginLeft: '10px' }}>
                    <i className="fab fa-facebook-f" style={{ fontSize: '20px' }}></i>
                  </a>
                  <a href="#" style={{ marginLeft: '10px' }}>
                    <i className="fab fa-instagram" style={{ fontSize: '20px' }}></i>
                  </a>
                  <a href="#" style={{ marginLeft: '10px' }}>
                    <i className="fab fa-linkedin-in" style={{ fontSize: '20px' }}></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 d-flex flex-column align-items-start">
              <h5 style={{ marginBottom: '10px' }}>Location</h5>
              <div className="branch-card">
                <h7 className="fw-600">Financial District, Hyderabad</h7>
                <ul className="footer-links" style={{ fontSize: '13px' }}>
                  <li>
                    <a href="https://maps.app.goo.gl/VmaqDDkbfLeMMd5v5">Level 12, The District, Financial District, Hyderabad, Telangana</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 d-flex flex-column align-items-start">
              <h5 style={{ marginBottom: '10px' }}>Get In Touch</h5>
              <div className="branch-card">
                <ul className="footer-links">
                  <li>
                    <a href="mailto:sales@casafortune.in">sales@casafortune.in</a>
                  </li>
                  <li>
                    <a href="tel:+919063683999">+91 90636 83999</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="foot" style={{ paddingTop: '20px' }}>
          <div className="row">
            <div className="col-lg-6">
              <p className="fsz-13" style={{ fontSize: '12px' }}>© 2024 CASAFORTUNE. All Right Reserved</p>
            </div>
           
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;