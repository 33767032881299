import React from 'react';

function MainInfo() {
  return (
    <section className="tc-main-info-style2">
      <div className="container">
        <div className="row gx-0">
          <div className="col-lg-4">
            <h4 className="fsz-75 fw-bold ">
              Carpentry Work & Joinery
            </h4>
            
          </div>
          <div className="col-lg-6 offset-lg-2 mt-5 mt-lg-0">
            <h6
              className="fsz-24 fw-400 mt-4 mt-lg-0 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              At CASA FORTUNE, we specialize in Carpentry Work & Joinery with a unique blend of traditional craftsmanship and contemporary innovation. Our mastery is evident in the precision and artistry we bring to every project, whether it’s crafting custom woodwork that reflects your style or providing tailored joinery solutions for homes, offices, and commercial spaces. With a commitment to quality materials and functional design, we transform wood into enduring, functional, and visually captivating creations, making CASA FORTUNE the ultimate choice for all your carpentry and joinery needs.
            </h6>
            
            
            
          </div>
        </div>
       
      </div>
    </section>
  );
}

export default MainInfo;
