import React from 'react';

function MainInfo() {
  return (
    <section className="tc-main-info-style2">
      <div className="container">
        <div className="row gx-0">
          <div className="col-lg-4">
            <h4 className="fsz-75 fw-bold ">
              Commercial Kitchen Design & Build
            </h4>
            
          </div>
          <div className="col-lg-6 offset-lg-2 mt-5 mt-lg-0">
            <h6
              className="fsz-24 fw-400 mt-4 mt-lg-0 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              Designing and building efficient and hygienic Commercial Kitchens is an art that demands precise planning, compliance with standards, and seamless functionality. At CASA FORTUNE, we specialize in creating cutting-edge kitchen spaces that cater to the needs of restaurants, hotels, cafés, and food processing units. Our commercial kitchens are designed to streamline workflow, enhance productivity, and ensure compliance with health and safety regulations.
              </h6>
          </div>
        </div>
       
      </div>
    </section>
  );
}

export default MainInfo;
