import React from 'react';

function MainInfo() {
  return (
    <section className="tc-main-info-style2">
      <div className="container">
        <div className="row gx-0">
          <div className="col-lg-4">
            <h4 className="fsz-75 fw-bold ">
              Construction
            </h4>
            
          </div>
          <div className="col-lg-6 offset-lg-2 mt-5 mt-lg-0">
            <h6
              className="fsz-24 fw-400 mt-4 mt-lg-0 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              The cycle of change persists, influencing not only the realm of fashion but also the domain of civil construction and development. Amidst discussions about social class, the significance of well-executed construction has emerged as another channel for self-expression. Whether applied to commercial projects or personal living spaces and interior designs, the civil construction sector now transcends mere aesthetics; it encapsulates individuality, and this is precisely the mission of CASA FORTUNE.
            </h6>
            
            
            
          </div>
        </div>
       
      </div>
    </section>
  );
}

export default MainInfo;
