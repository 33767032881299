import React from 'react';
import emailjs from 'emailjs-com';

function Contact() {
  const sendEmail = (e) => {
    e.preventDefault(); // Prevents page reload on form submission

    emailjs.sendForm(
      'service_9qm7e2o', // Service ID
      'template_nwy4tcx', // Template ID
      e.target, // The form data
      'Kh6cMoTwddYRD6cWK' // Replace with your actual EmailJS user ID from your EmailJS account
    )
    .then((result) => {
      console.log("Email successfully sent:", result.text);
      alert("Your message has been sent successfully!");
    })
    .catch((error) => {
      console.log("Error sending email:", error.text);
      alert("Failed to send your message. Please try again.");
    });

    e.target.reset(); // Clears the form after submission
  };

  return (
    <section className="tc-contact-style3">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="map-side">
              <h5 className="fsz-45 text-uppercase mb-90 js-splittext-lines">
                our locations
              </h5>
              <div className="map">
                <img
                  src="/home3_construction/assets/img/maps.png"
                  alt="Location Map"
                  className="map-img"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-8 offset-lg-1">
            <div className="contact-info">
              <div className="row">
                <div className="col-lg-6">
                  <div className="info-card mb-30 js-splittext-lines">
                    <h4 className="fsz-45 text-uppercase mb-30"> Hyderabad </h4>
                    <ul className="color-666 lh-6">
                      <li>
                        <a href="#">
                          Casa Fortune Developers Private Limited, The District, Level 12 near Regus, Financial District, Hyderabad, Telangana
                        </a>
                      </li>
                      <li>
                        <a href="mailto:sales@casafortune.in"> sales@casafortune.in </a>
                      </li>
                      <li>
                        <a href="tel:+919063683999"> +91 90636 83999 </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Contact Form */}
            <form onSubmit={sendEmail} className="form d-block mt-100">
              <div className="title mb-40 js-splittext-lines">
                <h4 className="fsz-45 text-uppercase mb-30"> Get In Touch </h4>
                <p className="text-danger">
                  * Marked fields are required to fill
                </p>
              </div>
              <div
                className="form-body wow fadeInUp slow"
                data-wow-delay="0.3s"
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <label htmlFor="name" className="fsz-12 text-uppercase mb-1">
                        Full name <span className="text-danger"> * </span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter your name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <label htmlFor="email" className="fsz-12 text-uppercase mb-1">
                        Email address <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter your email address"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <label htmlFor="phone" className="fsz-12 text-uppercase mb-1">
                        Phone number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder="Enter your phone number"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-30">
                      <label htmlFor="subject" className="fsz-12 text-uppercase mb-1">
                        Subject (optional)
                      </label>
                      <input
                        type="text"
                        name="subject"
                        className="form-control"
                        placeholder="Enter your subject"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-30">
                      <label htmlFor="message" className="fsz-12 text-uppercase mb-1">
                        Message
                      </label>
                      <textarea
                        rows="9"
                        name="message"
                        className="form-control"
                        placeholder="Write your message here"
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="butn borderd bg-white py-2 px-3 text-uppercase ltspc-1 hover-bg-yellow1 mt-60"
                >
                  <span>
                    Send message
                    <i className="small ms-1 ti-arrow-top-right"></i>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
