import React from 'react';
import emailjs from 'emailjs-com';

function ContactForm() {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_9qm7e2o', // Replace with your actual Service ID
      'template_nwy4tcx', // Replace with your actual Template ID
      e.target,
      'Kh6cMoTwddYRD6cWK' // Replace with your actual User ID
    )
    .then((result) => {
      console.log("Email sent:", result.text);
      alert("Your message has been sent successfully!");
    })
    .catch((error) => {
      console.error("Error:", error.text);
      alert("There was an error sending your message. Please try again.");
    });

    e.target.reset(); // Clears the form after submission
  };

  return (
    <section className="tc-contact-form-style1">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-4">
            <div className="info wow fadeInUp slow" data-wow-delay="0.2s">
              <h3 className="fsz-45 fw-500 mb-80">
                Let us help build your dream!
              </h3>
              <p className="fsz-14 color-666 mt-15">
                Your email address will not be published. <br /> Required fields
                are marked <span className="text-danger"> * </span>
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <form
              onSubmit={sendEmail}
              className="form mt-5 mt-lg-0 wow fadeInUp slow"
              data-wow-delay="0.4s"
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mb-30">
                    <label>
                      Full name <span className="color-orange1"> * </span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your name"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-30">
                    <label>
                      Email address <span className="color-orange1">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Your email address"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-30">
                    <label>
                      Phone <span className="color-999"> (optional) </span>
                    </label>
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      placeholder="Your phone number"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-30">
                    <label>
                      Subject <span className="color-orange1"> * </span>
                    </label>
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      placeholder="Your subject"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-30">
                    <label>Message</label>
                    <textarea
                      rows="6"
                      name="message"
                      placeholder="Write your message here"
                      className="form-control"
                      required
                    ></textarea>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="butn hover-bg-orange1 text-capitalize bg-white rounded-pill mt-40"
              >
                <span>
                  Send Your Message
                  <i className="fal fa-arrow-up-right ms-2"></i>
                </span>
              </button>
            </form>
          </div>
        </div>
      </div>
      <img
        src="/innerpages/assets/img/contact_shap.png"
        alt="Decorative Shape"
        className="shap"
      />
    </section>
  );
}

export default ContactForm;
