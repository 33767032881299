import React, { useEffect } from 'react';
import Slider from 'react-slick';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Challenge() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      once: true, // Animation should happen only once
    });
  }, []);

  const settings = {
    dots: true,
    infinite: false, // Disable looping
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px', // Adjust the height as needed
    backgroundColor: '#f5f5f5',
  };

  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '500px',
    objectFit: 'contain',
    padding: '10px', // Add padding for better spacing
  };

  return (
    <section className="tc-challenge-style2">
      <div className="container">
        <h2
          className="fsz-45 text-capitalize mb-60"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Benfits of Turnkey Solutions:
        </h2>

        <h3 data-aos="fade-up" data-aos-delay="400">Timely Project Completition:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Turnkey projects typically adhere to set timelines due to the creation of a comprehensive master plan at the project’s outset. This strategic plan ensures organized implementation, efficient labour and material management, and minimizes the need for frequent approvals, as all details are solidified in collaboration with the client from the start. </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Budget Adherence:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          With design specifics and material requirements finalized prior to execution, turnkey projects are less prone to budget overruns. Sharing cost estimates with clients beforehand reduces the likelihood of unexpected expenses. While design and material changes during execution could impact costs, turnkey projects remain cost-effective due to swift project completion and established rates with vendors and contractors. </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Understanding to Your Design Vision:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Through a singular point of contact, Turnkey solutions provide a clear understanding of your design vision through a single point of contact. For productive commercial spaces, they combine ergonomic, functional, and aesthetic elements. Residential spaces prioritize comfort, aesthetics, and reflecting your lifestyle. Turnkey solutions are gaining momentum in Indian interiors for both commercial and residential projects, with growth in both civil construction and interiors. </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Residential Turnkey Rendeavours:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          In residential turnkey projects, we provide comprehensive services from design inception to realization. This includes assessments, approvals, site supervision, coordination with contractors and suppliers. 

At CASA FORTUNE, we offer holistic interior design and construction services. We work with diverse clients, including individuals, real estate firms, and architectural companies. Our expertise spans civil work, carpentry, flooring, plumbing, decor, and more. With careful attention to every project phase, we ensure on-time execution. CASA FORTUNE specializes in architecture, civil construction, and interior design for various establishments, from offices to villas. Our expertise also covers Interior Turnkey Projects for houses and apartments, from design to execution. </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Architecture and Interior Design: A Holistic Perspective</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Architecture is both a process and a result involving planning, designing, and constructing physical structures like buildings. These creations, representing culture and art, have historical significance. Architecture arranges form, space, and ambiance while considering function, technology, society, environment, and aesthetics. It coordinates materials, technology, light, and shadow, addressing conflicting needs. Architecture covers practical aspects like scheduling, cost, and construction. Architectural documentation, including drawings and plans, outlines building behaviour.

CASA FORTUNE sees “architecture” as encompassing intricate interior and exterior designs, reflecting artistry and functionality.

In Essence, architecture revolves carefully arranging how things look, the area they take up, and what they’re used for. This arrangement reflects practical, technical, social, environmental, and artistic ideas. It involves using materials, technology, and light while solving problems that come up. CASA FORTUNE believes in this way of thinking and uses the word “architecture” to describe the well-crafted insides and outsides that go beyond what’s usual. </p>



      </div>



      {/* Image Slider Section */}
      <div className="content float_box_container">
        <Slider {...settings}>
          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/02.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/02.jpg"
                alt="Luxury Interior"
                style={imageStyle}
              />
            </a>
          </div>

          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/24.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/24.jpg"
                alt="Project Management"
                style={imageStyle}
              />
            </a>
          </div> 
        </Slider>
      </div>
    </section>
  );
}

export default Challenge;

