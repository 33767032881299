import React from 'react';

function MainInfo() {
  return (
    <section className="tc-main-info-style2">
      <div className="container">
        <div className="row gx-0">
          <div className="col-lg-4">
            <h4 className="fsz-75 fw-bold ">
              Design Consultancy
            </h4>
            
          </div>
          <div className="col-lg-6 offset-lg-2 mt-5 mt-lg-0">
            <h6
              className="fsz-24 fw-400 mt-4 mt-lg-0 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              Welcome to our realm of exceptional design and comprehensive solutions. Situated in Hyderabad, we are a renowned interior design firm known for our expertise in crafting tailor-made, luxurious interiors and exquisite furnishings, all while maintaining an unwavering commitment to providing exceptional client service. Our capabilities span a broad spectrum of design services, including complete corporate, commercial, retail, hospitality, and luxury residential interior design, interior architecture, project management, and bespoke furniture design. Our team is a diverse blend of professionals, including architects, interior designers, visualizers, project managers, furniture specialists, and a seasoned commercial team.
            </h6>
            
            
          </div>
        </div>
       
      </div>
    </section>
  );
}

export default MainInfo;
