import React from 'react';

function MainInfo() {
  return (
    <section className="tc-main-info-style2">
      <div className="container">
        <div className="row gx-0">
          <div className="col-lg-4">
            <h4 className="fsz-75 fw-bold ">
              Furniture Design
            </h4>
            
          </div>
          <div className="col-lg-6 offset-lg-2 mt-5 mt-lg-0">
            <h6
              className="fsz-24 fw-400 mt-4 mt-lg-0 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              At CASA FORTUNE, our expertise in Furniture Design brings together functionality, aesthetics, and customization. We believe that every piece of furniture tells a story, reflecting the individuality of its space while enhancing the overall design. Our approach to furniture design emphasizes both form and function, blending craftsmanship with innovation to create distinctive pieces tailored to fit residential and commercial environments.
              </h6>
          </div>
        </div>
       
      </div>
    </section>
  );
}

export default MainInfo;
