import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import data from '../../../data/innerpages/portfolio/latestcases'; // Adjust the path as needed

Modal.setAppElement('#root'); // Set the root element for accessibility

const LatestCases = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProjectImages, setSelectedProjectImages] = useState([]);
  const [currentProjectTitle, setCurrentProjectTitle] = useState('');
  const [projectType, setProjectType] = useState('commercial');

  const settings = {
    dots: true,
    infinite: false, // Ensure slider doesn't loop back
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const openModal = (images, title) => {
    // Ensure images are not accidentally duplicated
    const uniqueImages = Array.isArray(images) ? [...new Set(images)] : [images];
    console.log('Opening Modal with Images:', uniqueImages);

    setSelectedProjectImages(uniqueImages);
    setCurrentProjectTitle(title);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedProjectImages([]);
    setCurrentProjectTitle('');
  };

  const filteredProjects = data.filter((project) => {
    switch (projectType) {
      case 'commercial':
        return project.status === 'commercial';
      case 'residential':
        return project.status === 'residential';
      case 'ongoing':
        return project.status === 'ongoing';
      default:
        return false;
    }
  });

  const styles = {
    section: { padding: '60px 0' },
    container: { maxWidth: '1200px', margin: '0 auto', padding: '0 20px' },
    title: {
      fontSize: '45px',
      textTransform: 'capitalize',
      marginBottom: '40px',
      textAlign: 'center',
    },
    toggleContainer: { display: 'flex', justifyContent: 'center', marginBottom: '40px' },
    toggleButton: {
      padding: '10px 20px',
      margin: '0 10px',
      cursor: 'pointer',
      border: 'none',
      backgroundColor: '#333',
      color: 'white',
      fontSize: '16px',
      borderRadius: '5px',
    },
    activeButton: { backgroundColor: '#007BFF' },
    row: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(30%, 1fr))',
      gap: '20px',
    },
    column: { width: '100%' },
    mainImage: {
      width: '100%',
      height: '250px',
      objectFit: 'cover',
      cursor: 'pointer',
      borderRadius: '5px',
    },
    modal: {
      overlay: { background: 'rgba(0, 0, 0, 0.8)' },
      content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        width: '80vh',
        height: '80vh',
        margin: 'auto',
        position: 'relative',
      },
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      background: 'transparent',
      border: 'none',
      color: 'black',
      fontSize: '30px',
      cursor: 'pointer',
    },
    modalTitle: { fontSize: '24px', marginBottom: '10px', textAlign: 'center', color: 'black' },
    modalImage: {
      width: '100%',
      height: 'auto',
      maxHeight: '60vh',
      objectFit: 'cover',
    },
    sliderContainer: { width: '100%', maxWidth: '80vh', marginBottom: '20px' },
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <h3 style={styles.title}>Latest Projects</h3>

        <div style={styles.toggleContainer}>
          {['commercial', 'residential', 'ongoing'].map((type) => (
            <button
              key={type}
              style={{
                ...styles.toggleButton,
                ...(projectType === type ? styles.activeButton : {}),
              }}
              onClick={() => setProjectType(type)}
            >
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </button>
          ))}
        </div>

        <div className="cases">
          <div style={styles.row}>
            {filteredProjects.map((project, index) => (
              <div key={index} style={styles.column}>
                <h4>{project.title}</h4>
                <div className="case-card">
                  <img
                    src={project.images[0]}
                    alt={project.title}
                    style={styles.mainImage}
                    onClick={() => openModal(project.images, project.title)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Project Images"
        style={styles.modal}
      >
        <button onClick={closeModal} style={styles.closeButton}>✖</button>
        <h2 style={styles.modalTitle}>{currentProjectTitle}</h2>

        <div style={styles.sliderContainer}>
          <Slider {...settings}>
            {selectedProjectImages.map((image, index) => (
              <div key={`${image}-${index}`}>
                <img
                  src={image}
                  alt={`Project image ${index + 1}`}
                  style={styles.modalImage}
                />
              </div>
            ))}
          </Slider>
        </div>
      </Modal>
    </section>
  );
};

export default LatestCases;
