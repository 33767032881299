import React from 'react';

function Header() {
  return (
    <header className="tc-header-preview">
      <div
        className="slider-card"
        style={{
          position: 'relative',
          height: '600px', // Reduced height from 1000px to 600px
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* Background Image */}
        <div
          className="img"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
          }}
        >
          <img
            src="/innerpages/assets/img/about/header6.jpg"
            alt="Banner"
            className="img-cover"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover', // Ensures the image covers the container without distortion
            }}
          />
        </div>

        {/* Info Section */}
        <div
          className="info"
          style={{
            position: 'relative',
            zIndex: 2, // Ensures the content is above the image and overlay
            width: '100%',
            color: '#fff', // White text for better visibility on dark backgrounds
            textAlign: 'center', // Center-align text
          }}
        >
          <div className="container">
            {/* First Row: Rotate Box */}
            

            {/* Second Row: Heading */}
            <div className="row ps-lg-0 justify-content-center">
              <div className="col-lg-12">
                {/* Adjusted Font Size for Casa Fortune */}
                <h1 style={{ fontSize: '5.5rem' }}>Our Services</h1><br></br>
                <p style={{color:'yellow', fontSize: '1.25rem' }}>We Have Solutions for All Your Space Related Issues!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
