import React, { useEffect } from 'react';
import Slider from 'react-slick';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Challenge() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      once: true, // Animation should happen only once
    });
  }, []);

  const settings = {
    dots: true,
    infinite: false, // Disable looping
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px', // Adjust the height as needed
    backgroundColor: '#f5f5f5',
  };

  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '500px',
    objectFit: 'contain',
    padding: '10px', // Add padding for better spacing
  };

  return (
    <section className="tc-challenge-style2">
      <div className="container">
        

        
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          “At CASA FORTUNE, we pride ourselves on offering comprehensive building construction services that fuse design, engineering, and craftsmanship through the expertise of our skilled professionals. Our distinctive approach involves meticulous attention to detail from concept to handover, emphasizing quality, safety, and sustainability in line with industry standards. With a diverse portfolio spanning residential, commercial, industrial, and institutional projects, we consistently exceed expectations, delivering on time and within budget. When you choose CASA FORTUNE, you choose a dedicated partner committed to realizing your vision with precision and professionalism. Experience limitless construction possibilities with us, where excellence is the cornerstone of every project.”
        </p>

       
          
      </div>

      {/* Image Slider Section */}
      <div className="content float_box_container">
        <Slider {...settings}>
          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/01.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/01.jpg"
                alt="Luxury Interior"
                style={imageStyle}
              />
            </a>
          </div>

          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/09.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/09.jpg"
                alt="Project Management"
                style={imageStyle}
              />
            </a>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default Challenge;





