import React, { useEffect } from 'react';
import Slider from 'react-slick';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Challenge() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      once: true, // Animation should happen only once
    });
  }, []);

  const settings = {
    dots: true,
    infinite: false, // Disable looping
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px', // Adjust the height as needed
    backgroundColor: '#f5f5f5',
  };

  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '500px',
    objectFit: 'contain',
    padding: '10px', // Add padding for better spacing
  };

  return (
    <section className="tc-challenge-style2">
      <div className="container">
        <h2
          className="fsz-45 text-capitalize mb-60"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Our Commercial Kitchen Solutions:
        </h2>

        <h3 data-aos="fade-up" data-aos-delay="400">Ergonomic Layout Planning:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          We design layouts with an efficient flow that minimizes movement and ensures seamless interaction between kitchen zones—preparation, cooking, cleaning, and storage. </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Customized Equipment Integration:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
            We work with top-tier kitchen equipment suppliers to install specialized machinery, such as commercial ovens, hoods, refrigeration units, and dishwashers, to suit your operational needs. </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Durable & Hygienic Regulations:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          We prioritize high-quality stainless steel and other non-porous materials that withstand the rigors of commercial kitchens while ensuring easy cleaning and hygiene. </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Compilance with Regulations:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
        Our kitchens adhere to stringent food safety and health regulations, ensuring proper ventilation, drainage, and sanitation systems are in place. </p>

       

      </div>



      {/* Image Slider Section */}
      <div className="content float_box_container">
        <Slider {...settings}>
          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/kitchen.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/kitchen.jpg"
                alt="Luxury Interior"
                style={imageStyle}
              />
            </a>
          </div>

          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/kitchen1.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/kitchen1.jpg"
                alt="Project Management"
                style={imageStyle}
              />
            </a>
          </div> 
        </Slider>
      </div>
    </section>
  );
}

export default Challenge;

