import React from 'react';

function MainInfo() {
  return (
    <section className="tc-main-info-style2">
      <div className="container">
        <div className="row gx-0">
          <div className="col-lg-4">
            <h4 className="fsz-75 fw-bold ">
              Turnkey Solutions
            </h4>
            
          </div>
          <div className="col-lg-6 offset-lg-2 mt-5 mt-lg-0">
            <h6
              className="fsz-24 fw-400 mt-4 mt-lg-0 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
             Designing and constructing a commercial space can be overwhelming, the main challenge lies in finding the right individuals who can grasp and execute your design vision. Coordinating these different talents to bring your vision to life is complex, as each contractor’s work depends on the completion of the previous one. Differing interpretations among contractors can complicate matters, impacting the overall quality and potentially exceeding the budget.</h6>
          </div>
        </div>
       
      </div>
    </section>
  );
}

export default MainInfo;
