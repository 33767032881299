import React from 'react';
import emailjs from 'emailjs-com';

function Careers() {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_9qm7e2o', // Replace with your Service ID
      'template_y3fa90k', // Replace with your Template ID
      e.target,
      'Kh6cMoTwddYRD6cWK' // Replace with your User ID
    )
    .then((result) => {
      console.log("Email sent:", result.text);
      alert("Your application has been submitted successfully!");
    })
    .catch((error) => {
      console.error("Error:", error.text);
      alert("There was an error submitting your application. Please try again.");
    });

    e.target.reset(); // Clear the form after submission
  };

  return (
    <div style={containerStyle}>
      {/* Left Content Section */}
      <div style={leftSectionStyle}>
        <h1 style={headingStyle}>CHOOSE A JOB YOU LOVE</h1>
        <p style={paragraphStyle}>
        At Casa Fortune Developers Private Limited, our dedicated and talented workforce is our greatest asset. If you are a driven individual with the passion to align with our vision and elevate your career to new heights, we welcome you to explore the diverse career opportunities we offer. Join us in building a future where innovation meets excellence. To get started, kindly fill out the form on this page and share your credentials with us.
        </p>
      </div>

      {/* Right Form Section */}
      <div style={rightSectionStyle}>
        <form onSubmit={sendEmail}>
          <div style={formRowStyle}>
            <input type="text" name="name" placeholder="YOUR NAME*" style={inputStyle} required />
            <input type="text" name="phone" placeholder="YOUR PHONE NO" style={inputStyle} />
          </div>

          <div style={formRowStyle}>
            <input type="email" name="email" placeholder="YOUR EMAIL*" style={inputStyle} required />
            <input type="text" name="experience" placeholder="PROFESSIONAL EXPERIENCE" style={inputStyle} />
          </div>

          <div style={formRowStyle}>
            <select name="position" style={inputStyle} required>
              <option value="" disabled selected>POSITION*</option>
              <option value="Site Engineer">Site Engineer</option>
              <option value="Planning Engineer">Planning Engineer</option>
              <option value="procurement engineer">Procurement Manager</option>
              <option value="Project Manager">Project Manager</option>
              <option value="Purchase Manager">Purchase Manager</option>
              <option valuee="Billing & Estimation Manager">Billing & Estimation Manager</option>
            </select>
            <input type="text" name="current_position" placeholder="CURRENT POSITION*" style={inputStyle} required />
          </div>

          <div style={{ marginBottom: '20px' }}>
            <input type="text" name="resume" placeholder="Resume Link" style={fileInputStyle} />
          </div>

          <button type="submit" style={buttonStyle}>
            APPLY
          </button>
        </form>
      </div>
    </div>
  );
}

// CSS-in-JS Styles
const containerStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  minHeight: 'auto',
  padding: '50px 5%',
  gap: '30px',
};

const leftSectionStyle = {
  flex: 1,
  minWidth: '300px',
  paddingRight: '20px',
};

const rightSectionStyle = {
  flex: 1,
  minWidth: '300px',
  border: '2px solid #f0c419',
  borderRadius: '10px',
  padding: '30px',
};

const headingStyle = {
  fontSize: '3rem',
  fontWeight: 'bold',
};

const paragraphStyle = {
  marginTop: '20px',
  fontSize: '1.2rem',
  lineHeight: '1.8',
};

const formRowStyle = {
  display: 'flex',
  gap: '20px',
  marginBottom: '20px',
};

const inputStyle = {
  flex: 1,
  padding: '10px',
  fontSize: '1rem',
  borderRadius: '5px',
  border: '1px solid #ccc',
};

const fileInputStyle = {
  width: '100%',
  padding: '10px',
  fontSize: '1rem',
  borderRadius: '5px',
  border: '1px solid #ccc',
};

const buttonStyle = {
  backgroundColor: '#f0c419',
  border: 'none',
  padding: '15px 30px',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: '#fff',
  cursor: 'pointer',
  display: 'inline-block',
};

export default Careers;
