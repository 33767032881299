import React from 'react';
import data from '../../data/home3_construction/partners';

function Partners() {
  return (
    <section
      className="tc-partners-style3"
      style={{
        backgroundImage: `url('/home3_construction/assets/img/bg_pattern.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className="container">
        <div className="title mb-80" style={{ textAlign: 'center' }}>
          <h5
            className="fsz-30 text-uppercase wow fadeInUp slow"
            data-wow-delay="0.2s"
            style={{
              fontSize: '36px', // Match font size of "Latest Projects"
              fontWeight: 'bold', // Match boldness of "Latest Projects"
              textAlign: 'center',
              marginBottom: '10px',
            }}
          >
            OUR CLIENTS
          </h5>
          <p
            className="text fsz-18 wow fadeInUp slow"
            data-wow-delay="0.4s"
            style={{
              textAlign: 'center',
              marginTop: '10px',
            }}
          >
            We are proud to have been trusted and chosen by trusted partners
            for the past 25 years, here are the prominent names.
          </p>
        </div>

        <div className="logos">
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between', // Align items evenly with less space
              gap: '5px', // Reduced gap between each item to bring columns closer
            }}
          >
            {data.map((item, i) => (
              <a
                key={i}
                href="#"
                className="logo-card wow fadeInUp slow"
                data-wow-delay={item.delay}
                style={{
                  flex: '1 1 23%', // Adjust width to fit four items per row with minimal space
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  marginBottom: '10px',
                }}
              >
                <img src={item.img} alt={item.title} className="logo" style={{ marginBottom: '5px' }} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partners;
