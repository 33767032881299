import React from 'react';

function Header() {
  return (
    <header className="tc-header-preview">
      <div
        className="slider-card"
        style={{
          position: 'relative',
          height: '600px', // Reduced height from 1000px to 600px
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* Background Image */}
        <div
          className="img"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
          }}
        >
          <img
            src="/innerpages/assets/img/about/header2.jpg"
            alt="Banner"
            className="img-cover"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover', // Ensures the image covers the container without distortion
            }}
          />
        </div>

        {/* Info Section */}
        <div
          className="info"
          style={{
            position: 'relative',
            zIndex: 2, // Ensures the content is above the image and overlay
            width: '100%',
            color: '#fff', // White text for better visibility on dark backgrounds
            textAlign: 'center', // Center-align text
          }}
        >
          <div className="container">
            {/* First Row: Rotate Box */}
            <div className="row justify-content-center">
              <div className="col-lg-5 offset-lg-6">
                <div className="rotate-box" data-speed="1" data-lag="0.4">
                  <a
                    href="#"
                    className="rotate-circle fsz-35 rotate-text d-inline-block text-uppercase"
                  >
                    <svg className="textcircle" viewBox="0 0 500 500">
                      <defs>
                        <path
                          id="textcircle"
                          d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                        ></path>
                      </defs>
                      <text>
                        <textPath href="#textcircle" textLength="900">
                          • Years of experience • since 2010
                        </textPath>
                      </text>
                    </svg>
                  </a>
                  <span className="num">14</span>
                </div>
              </div>
            </div>

            {/* Second Row: Heading */}
            <div className="row ps-lg-0 justify-content-center">
              <div className="col-lg-12">
                {/* Adjusted Font Size for Casa Fortune */}
                <h1 style={{ fontSize: '5.5rem' }}>Casa Fortune</h1><br></br>
                <p style={{color:'yellow', fontSize: '1.25rem' }}>Designing Destinies, Building Dreams</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
