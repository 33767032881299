// Challenge.js
import React, { useEffect } from 'react';
import Slider from 'react-slick';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Challenge() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      once: true, // Animation should happen only once
    });
  }, []);

  const settings = {
    dots: true,
    infinite: false, // Disable looping
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px', // Adjust the height as needed
    backgroundColor: '#f5f5f5',
  };

  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '400px',
    objectFit: 'contain',
    padding: '10px', // Add padding for better spacing
  };

  return (
    <section className="tc-challenge-style2">
      <div className="container">
        <h2
          className="fsz-45 text-capitalize mb-60"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Our Areas of Expertise:
        </h2>

        <h3 data-aos="fade-up" data-aos-delay="400">Luxury Interior Design:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Whether it’s a cozy lounge or a sophisticated workspace, our specialty lies in
          creating opulent interiors that seamlessly fuse luxury with functionality.
        </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Project Management:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Our strength lies in efficiently managing and delivering design projects.
        </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Integrated Design:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          We provide a comprehensive design service featuring experienced teams of architects
          and project managers.
        </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Diverse Portfolio:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Our portfolio spans projects in education, public estates, residential properties,
          and urban regeneration.
        </p>
      </div>

      {/* Image Slider Section */}
      <div className="content float_box_container">
        <Slider {...settings}>
          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/10.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/10.jpg"
                alt="Luxury Interior"
                style={imageStyle}
              />
            </a>
          </div>

          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/11.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/11.jpg"
                alt="Project Management"
                style={imageStyle}
              />
            </a>
          </div>

          

         
        </Slider>
      </div>
    </section>
  );
}

export default Challenge;
