import React, { useEffect } from 'react';
import Slider from 'react-slick';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Challenge() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      once: true, // Animation should happen only once
    });
  }, []);

  const settings = {
    dots: true,
    infinite: false, // Disable looping
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px', // Adjust the height as needed
    backgroundColor: '#f5f5f5',
  };

  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '500px',
    objectFit: 'contain',
    padding: '10px', // Add padding for better spacing
  };

  return (
    <section className="tc-challenge-style2">
      <div className="container">   
      <h2 className="fsz-45 text-capitalize mb-60 js-splittext-lines">
          Our Specialities:
        </h2> 
  <li style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
    Intelligent Space Planning
  </li>
  <li style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
    Conceptual & Detailed Design
  </li>
  <li style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
    Mechanical, Electrical, and Plumbing Design (MEP)
  </li>
  <li style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
    Full Approval Pack
  </li>
  <li style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
    Construction Drawings
  </li>
  <li style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
    Operation & Maintenance Manuals
  </li>
  <li style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>
    3D Rendering & Visualization
  </li>
</div>

      {/* Image Slider Section */}
      <div className="content float_box_container">
        <Slider {...settings}>
         

          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/20.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/20.jpg"
                alt="Project Management"
                style={imageStyle}
              />
            </a>
          </div>

          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/21.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/21.jpg"
                alt="Project Management"
                style={imageStyle}
              />
            </a>
          </div>

          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/22.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/22.jpg"
                alt="Project Management"
                style={imageStyle}
              />
            </a>
          </div>
          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/19.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/19.jpg"
                alt="Project Management"
                style={imageStyle}
              />
            </a>
          </div>

          

         
        </Slider>
      </div>
    </section>
  );
}

export default Challenge;
