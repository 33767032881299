import React, { useEffect } from 'react';
import Slider from 'react-slick';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Challenge() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      once: true, // Animation should happen only once
    });
  }, []);

  const settings = {
    dots: true,
    infinite: false, // Disable looping
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px', // Adjust the height as needed
    backgroundColor: '#f5f5f5',
  };

  const imageStyle = {
    maxWidth: '400px',
    maxHeight: '500px',
    objectFit: 'contain',
    padding: '10px', // Add padding for better spacing
  };

  return (
    <section className="tc-challenge-style2">
      <div className="container">
        <h2
          className="fsz-45 text-capitalize mb-60"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Our Core Services Include:
        </h2>

        <h3 data-aos="fade-up" data-aos-delay="400">MEP Studies:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          We conduct in-depth analyses and assesments to understand the unique requirements and challenges of each project. These studies inform our design and Implementation processes.
        </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Design and Engineering:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Our skilled team excels in developing MEP designs that optimize functionality, energy efficiency, and sustainability. We integrate cutting-edge technologies and adhere to industry standards.
        </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Workshop Drawings:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          We produce detailed workshop drawings that serve as a crucial reference for the construction phase, ensuring precise execution of our designs.
        </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Revit Modeling:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Leveraging advanced tools like Revit, we create accurate 3D models that facilitate seamless collaboration and visualization of MEP systems.
        </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Design Review and Validation:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Rigorous review and validation processes ensure that our designs align with project goals, regulations, and client expectations.
        </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Value Engineering:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          We constantly seek opportunities to enhance project value by optimizing designs, materials, and processes without compromising quality.
        </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Engineering Caluculations:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Our team performs thorough engineering caluculations to ensure the safety and reliability of MEP systems.
        </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Equipment Selection:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          We meticulously choose equipment and components that meet project specifications and performance criteria.
        </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Method of Statements:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          We develop clear and comprehensice method statements to guide construction processes, ensuring consistency and safety.
        </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Material Submittals:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Our material submittal process ensures that the right materials are procured and used in accordance with project requirements.     
        </p>

          <h3 data-aos="fade-up" data-aos-delay="400">Planning and Coordination:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
         Effective project planning and coordination are integral to our approach, ensuring smooth execution and timely delivery.     
       </p>

       <h3 data-aos="fade-up" data-aos-delay="400">As-Built Drawings:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
         We create accurate as-built drawings that reflect the final state of MEP systems, aiding maintenance and future modifications.     
         </p>



      </div>



      {/* Image Slider Section */}
      <div className="content float_box_container">
        <Slider {...settings}>
          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/13.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/13.jpg"
                alt="Luxury Interior"
                style={imageStyle}
              />
            </a>
          </div>

          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/14.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/14.jpg"
                alt="Project Management"
                style={imageStyle}
              />
            </a>
          </div>

          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/15.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/15.jpg"
                alt="Integrated Design"
                style={imageStyle}
              />
            </a>
          </div>

          

          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/16.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/16.jpg"
                alt="Diverse Portfolio"
                style={imageStyle}
              />
            </a>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default Challenge;

