import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages';
import AboutPage from './pages/innerpages/about';
import BlogPage from './pages/innerpages/blog';
import ContactPage from './pages/innerpages/contact';
import ProjectPage from './pages/innerpages/projects';
import ServicesPage from './pages/innerpages/services';

import SingleProject from './pages/innerpages/single_project';
import Designconsultancy from './pages/innerpages/designconsultancy';
import Turnkey from './pages/innerpages/turnkey';
import Careers from './pages/innerpages/careers';
import UnderConstruction from './pages/innerpages/under_construction';
import Construction from './pages/innerpages/construction';
import Interior from './pages/innerpages/interior';
import Carpenter from './pages/innerpages/carpentry';
import MEP from './pages/innerpages/mep';
import FURNITURE from './pages/innerpages/furniture';
import Kitchen from './pages/innerpages/kitchen';


import $ from 'jquery';

window.jQuery = $; 
window.$ = $;
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/innerpages/about" element={<AboutPage />} />
        <Route path="/innerpages/blog" element={<BlogPage />} />
        <Route path="/innerpages/contact" element={<ContactPage />} />
        <Route path="/innerpages/projects" element={<ProjectPage />} />
        <Route path="/innerpages/services" element={<ServicesPage />} />

        <Route path ="/innerpages/turnkey" element={<Turnkey />}/>
        
        <Route path="/innerpages/single_project" element={<SingleProject />} />
       
        <Route path="/innerpages/designconsultancy" element={<Designconsultancy />} />
       
        <Route path="/innerpages/construction" element={<Construction/>}/>
        
        <Route path="/innerpages/interior" element={<Interior />}/>
        
        <Route path="/innerpages/carpentry" element={<Carpenter />}/>
        
        <Route path="/innerpages/mep" element={<MEP />}/>
        
        <Route path="/innerpages/furniture" element={<FURNITURE />}/>



        <Route path="/innerpages/under_construction" element={<UnderConstruction/>}/>
      
        <Route path="/innerpages/careers" element={<Careers/>}/>
        
        <Route path="/innerpages/kitchen" element={<Kitchen/>}/>

        
        
        
      </Routes>
    </Router>
  );
}

export default App;
