import React from 'react';

function PhilosophySection() {
  return (
    <section className="tc-philosophy-section">
      <div className="container">
        <div className="row align-items-center">
          {/* Left Side: Image */}
          <div className="col-lg-6">
            <div className="philosophy-image-container">
              <img
                src="/innerpages/assets/img/final.png" // Replace with your actual image path
                alt="Philosophy Image"
                className="philosophy-image"
              />
              <div className="image-overlay"></div> {/* Gradient overlay */}
            </div>
          </div>

          {/* Right Side: Heading and Content */}
          <div className="col-lg-6">
            <div className="philosophy-content">
              <h2 className="philosophy-heading">Our Philosophy</h2>
              <p className="philosophy-text">
              We at Casa fortune will always try to ensure that the construction design and the interiors reflect the client's lifestyle requirements.
                We also respect your budget and customize solutions to work within it. Our involvement is thorough,
                providing an in-depth understanding of our process and design.
              </p>
              <p className="philosophy-text">
                Constant attention to detail is crucial for ensuring functionally useful and aesthetically appealing workspaces and homes.
              </p>
              <a href="/innerpages/contact" className="btn-primary">Get in Touch</a>
            </div>
          </div>
        </div>
      </div>

      {/* Embedded CSS */}
      <style jsx>{`
        .tc-philosophy-section {
          padding: 100px 0;
          background-color: #f7f7f7;
        }

        .philosophy-image-container {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border-radius: 10px;
        }

        .philosophy-image {
          width: 100%;
          max-width: 450px;
          height: auto;
          transition: transform 0.5s ease;
        }

        .image-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0));
          opacity: 0.3;
          border-radius: 10px;
        }

        .philosophy-image:hover {
          transform: scale(1.05); /* Subtle zoom on hover */
        }

        .philosophy-content {
          text-align: left;
          padding: 20px;
          background-color: white;
          border-radius: 8px;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
          transition: all 0.3s ease;
        }

        .philosophy-heading {
          font-size: 36px;
          font-weight: bold;
          margin-bottom: 20px;
          color: #333;
          position: relative;
        }

        .philosophy-heading::after {
          content: '';
          position: absolute;
          bottom: -8px;
          left: 0;
          width: 50px;
          height: 3px;
          background-color: #ff9800; /* Accent color */
        }

        .philosophy-text {
          font-size: 18px;
          line-height: 1.8;
          margin-bottom: 20px;
          color: #555;
        }

        .btn-primary {
          display: inline-block;
          background-color: #ff9800;
          color: #fff;
          padding: 10px 20px;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 1px;
          border-radius: 5px;
          transition: background-color 0.3s ease;
          text-decoration: none;
        }

        .btn-primary:hover {
          background-color: #e68900;
        }

        @media (max-width: 768px) {
          .tc-philosophy-section {
            padding: 60px 0;
          }

          .philosophy-content {
            margin-top: 20px;
          }

          .philosophy-heading {
            font-size: 28px;
          }

          .philosophy-text {
            font-size: 16px;
          }

          .philosophy-image {
            max-width: 300px;
          }
        }
      `}</style>
    </section>
  );
}

export default PhilosophySection;
