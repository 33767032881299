import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

// Service data with separate content for popup
const servicesData = [
  {
    title: 'Design Consultancy',
    description: 'Welcome to our realm of exceptional design and comprehensive solutions. Situated in Hyderabad, we are a renowned interior design firm known for our expertise in crafting tailor-made, luxurious interiors and exquisite furnishings.',
    content: `Our capabilities span a broad spectrum of design services, including complete corporate, commercial, retail, hospitality, and luxury residential interior design, interior architecture, project management, and bespoke furniture design.`,
    icon: 'la la-hard-hat',
    image: '/innerpages/assets/img/services/ser1.jpg',
    delay: '0.2s',
    link: '/innerpages/designconsultancy'
  },
  {
    title: 'Construction',
    description: 'At CASA FORTUNE, we pride ourselves on offering comprehensive building construction services that fuse design, engineering, and craftsmanship through the expertise of our skilled professionals.',
    content: `Our distinctive approach involves meticulous attention to detail from concept to handover, emphasizing quality, safety, and sustainability.`,
    icon: 'la la-bezier-curve',
    image: '/innerpages/assets/img/services/ser9.jpg',
    delay: '0.4s',
    link: '/innerpages/construction'
  },
  {
    title: 'Interior Design & Fit-out',
    description: 'Our interior designers interpret each client’s desires and style preferences to provide pioneering and exquisite interiors.',
    content: `Our services include intelligent space planning, conceptual & detailed design, MEP, full approval packs, and 3D rendering & visualization.`,
    icon: 'la la-bed',
    image: '/innerpages/assets/img/services/ser3.jpg',
    delay: '0.6s',
    link: '/innerpages/interior'
  },
  {
    title: 'Carpentry Work & Joinery',
    description: 'We specialize in Carpentry Work & Joinery with a unique blend of traditional craftsmanship and contemporary innovation.',
    content: `Our mastery is evident in the precision and artistry we bring to every project, crafting custom woodwork that reflects your style.`,
    icon: 'la la-comments',
    image: '/innerpages/assets/img/services/ser4.jpg',
    delay: '0.8s',
    link: '/innerpages/carpentry'
  },
  {
    title: 'MEP Design & HVAC',
    description: 'Our core specialization lies in MEP design and execution, providing extensive services tailored to meet project demands.',
    content: `We develop designs that optimize functionality and create accurate 3D models, including HVAC systems for indoor air quality.`,
    icon: 'la la-comments',
    image: '/innerpages/assets/img/services/ser5.jpg',
    delay: '1.0s',
    link: '/innerpages/mep'
  },
  {
    title: 'Furniture Design',
    description: 'Our Furniture Design service focuses on creating custom, bespoke furniture pieces.',
    content: `Our designers and craftsmen work closely with clients to design furniture that reflects personal style and complements interiors.`,
    icon: 'la la-couch',
    image: '/innerpages/assets/img/services/ser7.jpg',
    delay: '1.2s',
    link: '/innerpages/furniture'
  },
  {
    title: 'Commercial Kitchen Design & Build',
    description: 'We specialize in designing and building efficient commercial kitchens tailored to the food service industry.',
    content: `Our team provides end-to-end solutions, from layout planning to equipment selection, ensuring compliance and optimal workflow.`,
    icon: 'la la-utensils',
    image: '/innerpages/assets/img/services/ser8.jpg',
    delay: '1.2s',
    link: '/innerpages/kitchen'
  },
  {
    title: 'Turnkey Solutions',
    description: 'We offer turnkey solutions, managing every aspect of your project from civil work to furniture installation.',
    content: `Our comprehensive approach streamlines the process, providing a single point of contact for contractors and specialists.`,
    icon: 'la la-comments',
    image: '/innerpages/assets/img/services/ser6.jpg',
    delay: '1.2s',
    link: '/innerpages/turnkey'
  }
];

function Services() {
  return (
    <section className="tc-services-style1">
      <div className="content section-padding section-padding-x">
        <div className="container">
          <div className="services">
            <div className="row">
              {servicesData.map((service, index) => (
                <div className="col-lg-4" key={index}>
                  <a
                    href={service.link}
                    className={`service-card wow fadeInUp ${index % 3 !== 2 ? 'mt-10' : ''}`}
                    data-wow-delay={service.delay}
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="icon">
                      <i className={service.icon}></i>
                    </div>
                    <h5 className="fsz-24 mb-20">{service.title}</h5>
                    <div className="img">
                      <img
                        src={service.image}
                        alt={service.title}
                        className="img-cover"
                      />
                    </div>
                    <div className="text color-666 mt-50">
                      {service.description}
                    </div>
                    <span
                      className="arrow"
                      style={{ cursor: 'pointer' }}
                    >
                      <i className="ti-arrow-top-right"></i>
                    </span>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="text-center">
            <a
              href="/innerpages/contact"
              className="butn rounded-pill mt-80 hover-bg-black bg-orange1 text-white"
            >
              <span>
                Get A Free Quote Now
                <i className="small ms-1 ti-arrow-top-right"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
