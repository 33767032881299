import React from 'react';

function About() {
  return (
    <section className="tc-about-style7">
      <div className="container">
        <div className="row justify-content-center">
          {/* Full-width text */}
          <div className="col-lg-10">
            <div className="info wow fadeInUp slow" data-wow-delay="0.2s">
              <h3 className="text-center">Discover Our Company's Distinctive Journey</h3>
              <br />
              <div className="text fsz-20 fw-500 mb-20">
                Casa Fortune Group is a diverse organization encompassing various
                sub-companies. We offer end-to-end solutions across four business
                verticals: Design & Building construction, Real Estate, Procurement,
                Imports & Exports. Backed by a robust history of delivering
                excellence in quality and a legacy of business leadership.
              </div>
              <div className="text fsz-20 fw-500 mb-20">
                We started our enterprise in 2010 and became a private limited
                company in 2020. CASA FORTUNE stands out as a prominent player in
                the Indian occupancy services sector. Our comprehensive offerings
                encompass corporate interior/exterior design, turnkey interiors,
                civil and interior services, MEP services, general contracting
                services, and imported furniture. As India continues to attract
                businesses worldwide due to its growing economy, the demand for
                top-tier occupancy services and interior design firms has never
                been greater. Corporations are increasingly relying on reputable
                interior design firms, architects, and contractors to infuse a
                touch of sophistication and creativity into their corporate spaces,
                aligning them seamlessly with their brand’s ethos. Our Interior
                Design solutions and General Contracting services are dedicated to
                delivering a harmonious blend of aesthetics, ergonomics, and
                productivity in workplace environments for our valued clients.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Embedded CSS */}
      <style jsx>{`
        .tc-about-style7 {
          padding: 80px 0;
          background-color: #f9f9f9;
        }

        .info h3 {
          font-size: 32px;
          color: #333;
          text-align: center;
        }

        .text {
          font-size: 18px;
          color: #555;
          text-align: justify;
          line-height: 1.6;
        }

        @media (max-width: 768px) {
          .info h3 {
            font-size: 28px;
          }

          .text {
            font-size: 16px;
          }
        }
      `}</style>
    </section>
  );
}

export default About;
