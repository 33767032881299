import React from 'react';

function Header() {
  const headerStyle = {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '400px', // Adjust height as needed
  };

  const containerStyle = {
    position: 'relative',
    zIndex: 2,
    display: 'flex',          // Use Flexbox
    alignItems: 'center',      // Vertical centering
    justifyContent: 'center',  // Horizontal centering
    width: '100%',
    height: '100%',            // Ensures it takes full header height
    padding: '20px',
    textAlign: 'center',
  };

  const infoStyle = {
    color: '#fff',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  };

  const titleStyle = {
    fontSize: '3rem',
    fontWeight: 'bold',
  };

  const imgStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',  // Ensures the image covers the area without distortion
    objectPosition: 'center',
    zIndex: 1,
  };

  return (
    <header style={headerStyle}>
      <div style={containerStyle}>
        <div style={infoStyle}>
          <h1 style={titleStyle}>Service Details</h1>
        </div>
      </div>
      <img
        src="/innerpages/assets/img/s_project2/header_bg.jpg"
        alt="Service Banner"
        style={imgStyle}
        data-speed="1.25"
      />
    </header>
  );
}

export default Header;
