import React from 'react';
import Slider from 'react-slick'; // Import Slider component from react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Testimonials() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <div style={{ cursor: 'pointer', color: 'blue' }}>Next</div>,
    prevArrow: <div style={{ cursor: 'pointer', color: 'blue' }}>Prev</div>,
  };

  return (
    <section
      style={{
        backgroundColor: '#f8f9fa',
        padding: '60px 0',
        position: 'relative',
        textAlign: 'left',
      }}
    >
      <div style={{ maxWidth: '1140px', margin: '0 auto', padding: '0 15px' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: '33.3333%', padding: '15px' }}>
            <h6
              style={{
                fontSize: '18px',
                textTransform: 'uppercase',
                lineHeight: '24px',
                marginBottom: '20px',
              }}
            >
              What clients say <br /> about us
            </h6>
            <div style={{ fontSize: '50px', color: '#ffa500' }}>
              <i className="la la-quote-right"></i>
            </div>
          </div>
          <div style={{ width: '66.6667%', padding: '15px' }}>
            <Slider {...settings}>
              <div>
                <div
                  style={{
                    backgroundColor: '#fff',
                    padding: '20px',
                    borderRadius: '5px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    marginRight: '30px',
                  }}
                >
                  <div
                    style={{
                      fontSize: '24px',
                      fontWeight: '600',
                      lineHeight: '1.6',
                      marginBottom: '20px',
                    }}
                  >
                    “I recently collaborated with Casa Fortune for the interior design and construction of my home, and I couldn't be more satisfied with the outcome. Their team displayed exceptional creativity and meticulous attention to detail throughout the project. They not only met but exceeded my expectations at every stage. From the initial design concepts to the final finishing touches, every aspect was carried out with precision and care, transforming my vision into reality.”</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '15px' }}>
                      <img
                        src="/innerpages/assets/img/team/team1.jpg"
                        alt=""
                        style={{
                          width: '60px',
                          height: '60px',
                          borderRadius: '50%',
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                    <div>
                      <h6
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          marginBottom: '5px',
                        }}
                      >
                        Dr Sravan A
                      </h6>
                      <p
                        style={{
                          fontSize: '14px',
                          margin: 0,
                          color: '#555',
                        }}
                      >
                        Senior Pediatrician  
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div
                  style={{
                    backgroundColor: '#fff',
                    padding: '20px',
                    borderRadius: '5px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    marginRight: '30px',
                  }}
                >
                  <div
                    style={{
                      fontSize: '24px',
                      fontWeight: '600',
                      lineHeight: '1.6',
                      marginBottom: '20px',
                    }}
                  >
                    “I had the pleasure of working with Casa Fortune on a renovation project of my commercial space, and I can't express how satisfied I am with the outcome. Their commitment to excellence and their innovative design ideas transformed my property into a masterpiece. From project planning to execution, they were organized, efficient, and transparent in communication. Casa Fortune turned my vision into reality, and I couldn't be happier.”
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '15px' }}>
                      <img
                        src="/innerpages/assets/img/team/team1.jpg"
                        alt=""
                        style={{
                          width: '60px',
                          height: '60px',
                          borderRadius: '50%',
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                    <div>
                      <h6
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          marginBottom: '5px',
                        }}
                      >
                        Rushika
                      </h6>
                      <p
                        style={{
                          fontSize: '14px',
                          margin: 0,
                          color: '#555',
                        }}
                      >
                        Project Manager, IT
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div
                  style={{
                    backgroundColor: '#fff',
                    padding: '20px',
                    borderRadius: '5px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    marginRight: '30px',
                  }}
                >
                  <div
                    style={{
                      fontSize: '24px',
                      fontWeight: '600',
                      lineHeight: '1.6',
                      marginBottom: '20px',
                    }}
                  >
                    “Casa Fortune is a game-changer in the real estate industry. Their expertise in interior design and construction is evident in every project they undertake. The quality of their work is unmatched, and their ability to transform workspaces is truly remarkable. The team is professional, responsive, and dedicated to delivering outstanding results.”
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '15px' }}>
                      <img
                        src="/innerpages/assets/img/team/team1.jpg"
                        alt=""
                        style={{
                          width: '60px',
                          height: '60px',
                          borderRadius: '50%',
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                    <div>
                      <h6
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          marginBottom: '5px',
                        }}
                      >
                        Nitesh Raj
                      </h6>
                      <p
                        style={{
                          fontSize: '14px',
                          margin: 0,
                          color: '#555',
                        }}
                      >
                        MD, Value Creed
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
