import React from 'react';
import Loader from '../components/common/Loader';
import Menu from '../components/home3_construction/Menu';
import StartButton from '../components/home3_construction/StartButton';
import Navbar3 from '../components/innerpage/Navbar3';
import Header from '../components/home3_construction/Header';
import PhilosophySection from '../components/home3_construction/PhilosophySection';
import Partners from '../components/home3_construction/Partners';

import Contact from '../components/home3_construction/Contact';
import Footer from '../components/innerpage/Footer';
import { Helmet } from 'react-helmet';

function Home3() {
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="/innerpages/assets/css/innerpages.css"
          
          />
        <script src="/common/assets/js/common_js.js"></script>
        <script src="/innerpages/assets/js/innerpages.js"></script>
       </Helmet>
      <div className="home-style3">
        <Loader />
        <Menu />
        <div className="smooth-scroll-content" id="scrollsmoother-container">
          <Navbar3 />        
          <Header />         
          <main>
            <PhilosophySection /> 
                     
            <Contact /> 
              
          </main>
          <Footer />
        </div>
        <StartButton />
      </div>
    </>
  );
}

export default Home3;
