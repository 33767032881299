import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Updated banner data with your image paths
const bannerData = [
  {
    id: 1,
    image: '/home_preview/assets/img/header3.jpg',
    alt: 'Banner 1',
    caption: '',
  },
  {
    id: 2,
    image: '/home_preview/assets/img/banner.jpg',
    alt: 'Banner 2',
    caption: '',
  },
  {
    id: 3,
    image: '/home_preview/assets/img/banner2.jpg',
    alt: 'Banner 3',
    caption: '',
  },
];

// Custom Arrow component with animation
const CustomArrow = (props) => {
  const { className, style, onClick, direction } = props;
  return (
    <div
      className={`custom-arrow ${className}`}
      style={{
        ...style,
        display: 'block',
        background: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '50%',
        padding: '10px',
        border: '2px solid yellow',
        zIndex: '1',
        transform: 'scale(1)',
        transition: 'transform 0.3s ease',
      }}
      onClick={onClick}
      onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
      onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="yellow"
        viewBox="0 0 16 16"
      >
        {direction === 'left' ? (
          <path
            fillRule="evenodd"
            d="M11.354 4.646a.5.5 0 0 1 0 .708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0z"
          />
        ) : (
          <path
            fillRule="evenodd"
            d="M4.646 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 1 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        )}
      </svg>
    </div>
  );
};

function Header() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    fade: true,
    arrows: true,
  };

  return (
    <header className="custom-header">
      <Slider {...sliderSettings}>
        {bannerData.map((banner) => (
          <div key={banner.id} className="slider-item">
            <div
              className="slider-image"
              style={{
                backgroundImage: `url(${banner.image})`,
                backgroundSize: 'cover',
                height: '800px',
              }}
            >
              <div className="slider-caption">
                <h1>{banner.caption}</h1>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </header>
  );
}

export default Header;
