import React from 'react';

function MainInfo() {
  return (
    <section className="tc-main-info-style2">
      <div className="container">
        <div className="row gx-0">
          <div className="col-lg-4">
            <h4 className="fsz-75 fw-bold ">
              MEP Design & HVAC
            </h4>
            
          </div>
          <div className="col-lg-6 offset-lg-2 mt-5 mt-lg-0">
            <h6 className="fsz-24 fw-400 mt-4 mt-lg-0 wow fadeInUp slow" data-wow-delay="0.2s">Our core specialization lies in MEP (Mechanical, Electrical, and Plumbing) design and execution, providing an extensive array of services tailored to address the multifaceted demands of projects within the built environment. Our proficiency spans a broad spectrum of project types, encompassing everything from individual single-family homes to expansive residential complexes, commercial properties, shopping malls, and healthcare facilities such as hospitals.

            </h6><br></br>
            <h6 className="fsz-24 fw-400 mt-4 mt-lg-0 wow fadeInUp slow" data-wow-delay="0.2s">Heating, ventilation, and air conditioning (HVAC) technology is all about making indoor and vehicle spaces comfy. Its main goal is to keep the temperature right and the indoor air quality good. Designing HVAC systems is a special part of mechanical engineering, based on thermodynamics, fluid mechanics, and heat transfer. Sometimes, it even includes refrigeration, called HVAC&R or HVACR, and “ventilating” gets shortened to HACR.
              </h6>
            
          </div>
          
        </div>
       
      </div>
    </section>
  );
}

export default MainInfo;
