import React, { useEffect } from 'react';
import Slider from 'react-slick';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Challenge() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      once: true, // Animation should happen only once
    });
  }, []);

  const settings = {
    dots: true,
    infinite: false, // Disable looping
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px', // Adjust the height as needed
    backgroundColor: '#f5f5f5',
  };

  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '500px',
    objectFit: 'contain',
    padding: '10px', // Add padding for better spacing
  };

  return (
    <section className="tc-challenge-style2">
      <div className="container">
        <h2
          className="fsz-45 text-capitalize mb-60"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Our Approach:
        </h2>

        <h3 data-aos="fade-up" data-aos-delay="400">Bespoke Creations:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Whether it’s a sleek office desk or a plush sofa, our team crafts bespoke furniture tailored to your taste and requirements. We collaborate closely with clients to ensure the furniture aligns perfectly with their vision. </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Innovative Materials:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
           We explore a variety of materials, including natural wood, metal, glass, fabrics, and sustainable alternatives, to ensure durability and modern appeal. </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Ergonomics & Comfort:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Thoughtful design is at the heart of our furniture. Every piece is engineered for comfort and function, ensuring long-term usability in both residential and commercial settings. </p>

        <h3 data-aos="fade-up" data-aos-delay="400">Seamless Integration:</h3>
        <p
          className="fsz-24 fw-400 mt-4 mt-lg-0"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Our designs complement the interiors they inhabit, ensuring that each element—whether a statement piece or modular furniture—enhances the environment. </p>


      </div>



      {/* Image Slider Section */}
      <div className="content float_box_container">
        <Slider {...settings}>
          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/furniture.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/furniture.jpg"
                alt="Luxury Interior"
                style={imageStyle}
              />
            </a>
          </div>

          <div style={containerStyle}>
            <a href="/innerpages/assets/img/s_project2/final/furniture1.jpg" data-fancybox="cases">
              <img
                src="/innerpages/assets/img/s_project2/final/furniture1.jpg"
                alt="Project Management"
                style={imageStyle}
              />
            </a>
          </div> 
        </Slider>
      </div>
    </section>
  );
}

export default Challenge;

